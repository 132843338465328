<script setup lang="ts">
const props = withDefaults(defineProps<{ disabled: boolean; hideDetails: boolean; rules: any }>(), {
  disabled: false,
  hideDetails: false,
  rules: null,
});

const emit = defineEmits(["click"]);
const checkValue = defineModel("checkValue");
</script>

<template>
  <div>
    <v-checkbox
      v-model:model-value="checkValue"
      :hide-details="props.hideDetails"
      :disabled="props.disabled"
      :rules="props.rules"
      @click="emit('click')"
    >
      <template #label>
        <span
          id="checkbox-data-privacy-label"
          class="body-medium"
        >
          <slot name="label" />
        </span>
      </template>
    </v-checkbox>
  </div>
</template>

<style scoped></style>
